<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      showPassword: false,
      username: "",
      password: "",
      submitted: false,
      message: null,
    };
  },

  computed: {
    ...mapGetters("session", ["loggingIn"]),
  },

  methods: {
    handleSubmit() {
      this.submitted = true;
      const { username, password } = this;
      if (username && password) {
        this.$store
          .dispatch("session/login", { username, password })
          .then(() => {
            this.$parent.close();
          })
          .catch(err => {
            // this.message = err;
            if (err.status === 404) {
              this.message = "Service currently unavailable.";
            } else {
              this.message =
                "Combinación de correo electrónico y contraseña inválida.";
            }
          });
      }
    },
  },
};
</script>

<template>
  <div id="authLogin">
    <section>
      <h2 class="title">Iniciar sesión</h2>
      <div class="media validation has-text-danger" v-if="message">
        <div class="media-left">
          <b-icon icon="exclamation-circle" size="is-medium" />
        </div>
        <div class="media-content">
          {{ message }}
        </div>
      </div>
      <form
        method="post"
        @submit.prevent="handleSubmit"
        autocomplete="on"
        key="login"
      >
        <div class="field">
          <b-input
            type="email"
            size="is-medium"
            id="user_login"
            name="email"
            rounded
            expanded
            placeholder="Correo electrónico"
            v-model="username"
            :disabled="loggingIn"
          />
          <div v-show="submitted && !username" class="invalid-feedback">
            Username is required
          </div>
        </div>
        <div class="field">
          <b-input
            type="password"
            size="is-medium"
            id="user_pass"
            name="pwd"
            rounded
            expanded
            placeholder="Contraseña"
            password-reveal
            v-model="password"
            :disabled="loggingIn"
          />
          <div v-show="submitted && !password" class="invalid-feedback">
            Password is required
          </div>
        </div>
        <!-- <a @click.prevent="showPassword = !showPassword">Show password</a> -->
        <div class="content">
          <button
            class="button is-primary is-rounded is-expanded is-medium"
            :class="{ 'is-loading': loggingIn }"
            :disabled="loggingIn"
          >
            Iniciar sesión
          </button>
        </div>
      </form>
      <div class="content">
        <a href="" type="is-text" @click.prevent="$parent.isAction = 'forgot'"
          >¿Olvidaste tu contraseña?</a
        >
      </div>
      <hr />
      <div class="content">
        ¿Aún no tienes una cuenta gratuita?
      </div>
      <b-button
        type="is-light"
        size="is-medium"
        rounded
        expanded
        @click="$parent.isAction = 'signup'"
        >Crear cuenta</b-button
      >
    </section>
  </div>
</template>
